import React, { useState } from "react";
import * as styles from "./ContactForm.module.scss";
import FormFileInput from "./FormFileInput";
import {getLabel} from "../contentful/data-provider";
// import ReCaptcha from "@pittica/gatsby-plugin-recaptcha"
// import { getLabel } from "../contentful/data-provider";
// import {useTranslation} from 'gatsby-plugin-react-i18next';

const CareerForm = ({positionTitle, labels}) => {
	// const {t} = useTranslation();

	// const [submitted, setSubmitted] = useState(false);
	const [checked, setChecked] = useState(false);

	// const submit = (token) => {}

	return (
		<div className={styles.container}>
			<div className={styles.inner}>
				<h2>{getLabel('apply-now-label', labels)}</h2>
				<form
					action='https://getform.io/f/5e0e0498-6f23-4941-bd71-38cc91207776'
					encType='multipart/form-data'
					method={"post"}
					className={styles.container}
				>

					<div className={styles.grid}>

						{/* NAME */}
						<div className={styles.inputContainer}>
							<label htmlFor='name' className={styles.label}>
								{getLabel('form-name-label', labels)}
							</label>
							<input id='name' name='name' className={styles.input} type='text' required={true}/>
						</div>


						{/* SURNAME */}
						<div className={styles.inputContainer}>
							<label htmlFor='surename' className={styles.label}>
								{getLabel('form-surname-label', labels)}
							</label>
							<input id='surename' name="surename" className={styles.input} type='text' required={true}/>
						</div>
						
						{/* SUBJECT */}
						<input type="hidden" id='career-position' name='career-position' value={positionTitle}/>

						{/* EMAIL */}
						<div className={styles.inputContainer}>
							<label htmlFor='email' className={styles.label}>
								{getLabel('form-email-label', labels)}
							</label>
							<input id='email' name="email" className={styles.input} type='email' required={true}/>
						</div>

						 {/* PHONE */}
						<div className={styles.inputContainer}>
							<label htmlFor='phone' className={styles.label}>
								{getLabel('form-phone-label', labels)}
							</label>
							<input id='phone' name="phone" className={styles.input} type='tel' required={true}/>
						</div>
					</div>


					{/* FILE */}
					<div className={styles.inputContainer}>
						<label htmlFor='document' className={styles.label}>
							{getLabel('form-document-label', labels)}
						</label>
						<FormFileInput
							id={'document'} name={'document'} label={getLabel('form-document-label', labels)}
							type={'file'} class={styles.input} required={true}
							uploadDocumentLabel={getLabel('form-upload-document-label', labels)}
							maximumFilesizeLabel={getLabel('form-maximum-filesize-label', labels)}
						/>
					</div>


					{/* MESSAGE */}
					<div className={styles.textAreaContainer}>
						<label htmlFor='message' className={styles.label}>
							{getLabel('form-message-label', labels)}
						</label>
						<textarea id='message' name="message" className={styles.textArea} type='email' rows="10" required={true}/>
					</div>


					{/* CHECKBOX */}
					<div className={styles.inputContainer}>
						<label className={styles.checkboxLabel}>
							{getLabel("agree-privacy-policy-label", labels)}
							<input
								id='checkbox' name="checkbox" className={styles.checkbox} type='checkbox' required={true}
								defaultChecked={checked}
								onChange={() => setChecked(!checked)}
								value={checked ? 'Terms accepted' : 'Terms not accepted'}
							/>
							<span className={styles.checkmark}>✓</span>
						</label>
					</div>

					{/* HONEYPOT */}
					<div>
						<input type="hidden" name="_gotcha" className={styles.hidden} />
					</div>

					 {/* SUBMIT */}
					<button type={"submit"} className={styles.button}>
						{getLabel("send-message-label", labels)}
					</button>
				</form>
			</div>
		</div>
	);
};

export default CareerForm;
